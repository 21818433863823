import useSWR from 'swr';
import { CleanUser } from '../export-types/cleaned-types';
import { fetcher } from '../services/api';

export type UserProps = CleanUser & {
  moneyBill: { cny: number; usd: number };
  deliveryDebt: number | null;
};

export function useUsers(
  role: string[],
  debouncedInternalId?: string,
  managerUUID?: string | null,
  sort?: string | null,
  take?: number,
  skip?: number,
  takeUnattached?: boolean,
) {
  const { data, isLoading, mutate } = useSWR<{
    total: number;
    items: Array<UserProps>;
  }>(
    () => ({
      url: '/user/v2',
      params: {
        takeUnattached,
        managerUUID,
        role,
        ...(debouncedInternalId
          ? {
              internalId: debouncedInternalId?.trim(),
              skip: 0,
            }
          : { skip }),
        take,
        sort,
        direction: sort === 'deliveryDebt' ? 'desc' : 'asc',
      },
    }),
    fetcher,
  );

  return { data, isLoading, mutate };
}

/** Хук для получения комиссии для каждого пользователя */
export function useUsersCommission(
  ids: string[],
  { enabled }: { enabled: boolean } = { enabled: true },
) {
  return useSWR<{
    list: {
      id: string;
      commission: number;
      promotionCommission?: {
        id: string;
        commission: number;
        endDate: Date;
      };
    }[];
  }>(
    () =>
      enabled && {
        url: '/client-expenses-purchase-markups/commission',
        params: {
          userIDs: ids,
        },
      },
    fetcher,
  );
}
