import React, { useRef } from 'react';
import Whisper from 'rsuite/Whisper';
import Tooltip from 'rsuite/Tooltip';
import { Header } from '../../common/header.component';
import { toMoney } from '../../../utils/common.utils';
import { AlertService } from '../../../services/alert.service';
import { useNavigate } from 'react-router-dom';
import { ModalsService } from '../../../services/modals.service';
import {
  Control,
  Controller,
  FieldPath,
  FieldPathValue,
  useForm,
  UseFormRegister,
  useFormState,
} from 'react-hook-form';
import {
  decimalValidation,
  required,
  urlValidation,
} from '../../../utils/validations';
import { InputWrapper } from '../../common/input-wrapper';
import useSWR from 'swr';
import { api, fetcher } from '../../../services/api';
import isDecimal from 'validator/es/lib/isDecimal';
import { uploadMediaInputFiles } from '../../../services/file-service';
import PriceImage from '../../../assets/purchase-order-price.jpg';
import PriceImage2X from '../../../assets/purchase-order-price@2x.jpg';
import PriceImage3X from '../../../assets/purchase-order-price@3x.jpg';
import RequestImage from '../../../assets/purchase-order-request-example.jpg';
import RequestImage2X from '../../../assets/purchase-order-request-example@2x.jpg';
import RequestImage3X from '../../../assets/purchase-order-request-example@3x.jpg';
import { User, UserSelect } from '../../common/user-select';
import { AdminPayPurchaseOrderModal } from '../../common/admin-pay-purchase-order-modal';
import { MediaInput, MediaInputValue } from '../../common/media-input';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import get from 'lodash/get';
import { Select } from '../../common/select.component';
import { useSuppliers } from '../../../api/suppliers';

export const AdminCreatePurchaseOrderScreen: React.FC = () => {
  const navigate = useNavigate();

  async function handleSubmit(
    action: FormAction,
    form: PurchaseOrderFormData<true>,
  ) {
    const requestFilesResponse = await uploadMediaInputFiles(
      form.order.requestFiles,
    );

    const {
      data: { purchaseOrder },
    } = await api.post('/purchase-order/v2/admin', {
      userId: form.user.id,
      name: form.order.name.trim(),
      link: form.order.link.trim(),
      productPrice: form.order.price,
      requestCount: form.order.requestCount
        ? Number(form.order.requestCount)
        : null,
      comment: form.order.comment.trim(),
      requestFiles: requestFilesResponse.map((response) => ({
        id: response.file.id,
      })),
      supplierId: form.supplierId,
    });

    const isPayed =
      action === 'justSave'
        ? false
        : await ModalsService.createModal(AdminPayPurchaseOrderModal, {
            purchaseOrderId: purchaseOrder.id,
            price: purchaseOrder.price,
          });

    if (isPayed) {
      AlertService.success(
        <div>
          <div className="fw-bold">
            Заказ №V{purchaseOrder.internalId} создан и оплачен
          </div>
          <div className="fs-14">
            За всеми изменениями по заказу вы можете наблюдать в разделе
            “Выкупы”.
          </div>
        </div>,
      );
    } else {
      AlertService.success(
        <div>
          <div className="fw-bold">
            Заказ №V{purchaseOrder.internalId} создан
          </div>
          <div className="fs-14">
            За всеми изменениями по заказу вы можете наблюдать в разделе
            “Выкупы”.
          </div>
        </div>,
      );
    }

    navigate(-1);
  }

  return (
    <>
      <Header />
      <div className="container" style={{ marginTop: -10 }}>
        <h1>Создать новый заказ на выкуп</h1>
        <PurchaseOrderForm onSubmit={handleSubmit} withUser={true} />
      </div>
    </>
  );
};

type FormAction = null | 'withPaid' | 'justSave';

type PurchaseOrderFormData<TWithUser extends boolean> = {
  order: {
    name: string;
    link: string;
    price: string;
    requestCount: string;
    comment: string;
    requestFiles: MediaInputValue[];
  };
  supplierId: number;
} & (TWithUser extends true ? { user: User } : {});

type PurchaseOrderFormProps<TWithUser extends boolean> = {
  onSubmit: (
    action: FormAction,
    form: PurchaseOrderFormData<TWithUser>,
  ) => Promise<void>;
  withUser?: TWithUser;
  defaultValues?: Partial<PurchaseOrderFormData<TWithUser>>;
};

const PurchaseOrderForm = <TWithUser extends boolean>(
  props: PurchaseOrderFormProps<TWithUser>,
) => {
  const { data: suppliers } = useSuppliers();

  const submitButton = useRef<FormAction>(null);

  const {
    register,
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    watch,
  } = useForm<PurchaseOrderFormData<true>>({
    defaultValues: props.defaultValues,
  });

  const onSubmit = handleSubmit(async function (form) {
    await props.onSubmit(submitButton.current, form);
  });

  const deliveryTooltip = (
    <Tooltip>
      <div className="et-tooltip" style={{ width: 150 }}>
        Ориентировочная стоимость доставки товаров от&nbsp;поставщика
        до&nbsp;транзитного склада
      </div>
    </Tooltip>
  );

  const price = watch('order.price');
  const [debouncedPrice, setDebouncedPrice] = React.useState(price);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedPrice(price);
    }, 250);

    return () => {
      clearTimeout(handler);
    };
  }, [price]);

  const user = watch('user');
  const [debouncedUser, setDebouncedUser] = React.useState(user);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedUser(user);
    }, 250);

    return () => {
      clearTimeout(handler);
    };
  }, [user]);

  const { data: calculation } = useSWR<{
    product?: string;
    markup?: string;
    delivery?: string;
    total?: string;
  }>(() => {
    if (!isDecimal(debouncedPrice)) return null;
    if (props.withUser && !debouncedUser) return null;

    return {
      url: '/purchase-order/calculate',
      params: {
        productPrice: debouncedPrice,
        userId: props.withUser ? debouncedUser.id : undefined,
      },
    };
  }, fetcher);

  return (
    <form className="row justify-content-center pt-4" onSubmit={onSubmit}>
      <div className="col-7 p-4">
        <Controller
          name="supplierId"
          control={control}
          rules={required}
          render={({ field }) => (
            <Select
              className="mb-3"
              title="Поставщик"
              placeholder="Выберите поставщика"
              options={
                suppliers?.map(({ id, name, location }) => ({
                  value: id,
                  label:
                    name +
                    (location?.name ? ` (откуда: ${location.name})` : ''),
                })) ?? []
              }
              {...field}
              error={errors.supplierId?.message}
            />
          )}
        />
        {props.withUser && (
          <Controller
            control={control}
            name="user"
            rules={{
              ...required,
            }}
            render={({ field, fieldState }) => (
              <InputWrapper
                className="mb-3"
                title="Клиент"
                required
                error={fieldState.error?.message}
              >
                <UserSelect placeholder="Клиент" {...field} />
              </InputWrapper>
            )}
          />
        )}

        <div className="mb-4">
          <PurchaseOrderItemForm
            register={register}
            control={control}
            path="order"
          />
        </div>
      </div>
      <div className="col-5">
        <div className="bg-gray-50 p-4">
          <h3 className="mb-3">Общая стоимость заказа</h3>
          <div className="d-flex flex-row justify-content-between mb-3">
            <div className="fs-14 color-gray-400">Стоимость товаров</div>
            <div>
              {calculation?.product ? (
                toMoney(calculation.product, 'cny')
              ) : (
                <i className="bi bi-three-dots color-gray-400" />
              )}
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between mb-3">
            <div className="fs-14 color-gray-400">Комиссия за выкуп</div>
            <div>
              {calculation?.markup ? (
                toMoney(calculation.markup, 'cny')
              ) : (
                <i className="bi bi-three-dots color-gray-400" />
              )}
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between mb-3">
            <div className="fs-14 color-gray-400">
              Доставка товаров до транзитного склада
              <Whisper
                placement="top"
                trigger="hover"
                speaker={deliveryTooltip}
              >
                <i className="ms-2 bi bi-question-circle" />
              </Whisper>
            </div>
            <div>
              {calculation?.delivery ? (
                toMoney(calculation.delivery, 'cny')
              ) : (
                <i className="bi bi-three-dots color-gray-400" />
              )}
            </div>
          </div>
          <hr />
          <div className="d-flex flex-row justify-content-between mb-4">
            <div>Итого:</div>
            <div>
              {calculation?.total ? (
                toMoney(calculation.total, 'cny')
              ) : (
                <i className="bi bi-three-dots color-gray-400" />
              )}
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-success w-100 mb-3"
            onClick={() => (submitButton.current = 'withPaid')}
            disabled={isSubmitting}
          >
            {isSubmitting && submitButton.current === 'withPaid' ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              'Сохранить и оплатить'
            )}
          </button>
          <button
            type="submit"
            className="btn btn-outline-success w-100"
            onClick={() => (submitButton.current = 'justSave')}
            disabled={isSubmitting}
          >
            {isSubmitting && submitButton.current === 'justSave' ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              'Только сохранить'
            )}
          </button>
          <div className="fs-14 color-gray-400 mt-3">
            Размещая заказ, вы соглашаетесь с правилами пользования сервисом
          </div>
        </div>
      </div>
    </form>
  );
};

type PurchaseOrderItemValue = {
  name: string;
  link: string;
  price: string;
  requestCount: string;
  comment: string;
  requestFiles: MediaInputValue[];
};

type PurchaseOrderItemFormProps<
  TFieldValues extends FieldValues,
  TContext = any,
> = {
  register: UseFormRegister<TFieldValues>;
  control: Control<TFieldValues, TContext>;
  onRemove?: () => void;
  path: keyof {
    [P in FieldPath<TFieldValues> as FieldPathValue<
      TFieldValues,
      P
    > extends PurchaseOrderItemValue
      ? P
      : never]: P;
  };
};

const PurchaseOrderItemForm = <
  TFieldValues extends FieldValues,
  TContext = any,
>(
  props: PurchaseOrderItemFormProps<TFieldValues, TContext>,
) => {
  const { control, register, onRemove } = props;
  const { errors } = useFormState({ control });

  const requestTooltip = (
    <Tooltip className="fs-14 text-start lh-sm p-2" style={{ maxWidth: 380 }}>
      <img
        src={RequestImage}
        srcSet={`${RequestImage} 1x, ${RequestImage2X} 2x, ${RequestImage3X} 3x`}
        height="328px"
        alt=""
        className="img-fluid rounded mb-2"
      />
      На скриншоте должно быть отчетливо видно выбранные модификации товара , их
      количество (для каждой модификации) и итоговую сумму.
      <br />
      Необходимая информация выделена зелеными прямоугольниками.
    </Tooltip>
  );

  const priceTooltip = (
    <Tooltip className="fs-14 text-start lh-sm p-2" style={{ maxWidth: 380 }}>
      <img
        src={PriceImage}
        srcSet={`${PriceImage} 1x, ${PriceImage2X} 2x, ${PriceImage3X} 3x`}
        height="328px"
        alt=""
        className="img-fluid rounded mb-2"
      />
      Суммарная стоимость выбранных товаров
    </Tooltip>
  );

  return (
    <div className="card border-0 rounded">
      <div className="card-header bg-gray-50 border-0 p-3">
        <InputWrapper
          error={
            // @ts-ignore todo
            get(errors, props.path)?.name?.message
          }
        >
          <div className="row g-1">
            <div className="col">
              <input
                type="text"
                placeholder="Например, чехлы для телефонов"
                className="form-control"
                {...register(
                  `${String(props.path)}.name` as FieldPath<TFieldValues>,
                )}
              />
            </div>
            <div className="col-auto">
              {onRemove && (
                <button
                  type="button"
                  className="btn px-3"
                  onClick={() => {
                    onRemove();
                  }}
                >
                  <i className="bi bi-trash" />
                </button>
              )}
            </div>
          </div>
          <div className="fs-14 color-gray-400 mt-1">
            Вы можете указать свое название, так будет проще найти заказ
          </div>
        </InputWrapper>
      </div>

      <div className="card-body border border-gray-200 border-top-0 rounded-bottom">
        <InputWrapper
          title="Ссылка на товар"
          className="mb-4"
          error={
            // @ts-ignore todo
            get(errors, props.path)?.link?.message
          }
          required
        >
          <input
            type="text"
            placeholder="Вставьте ссылку на страницу товара"
            className="form-control"
            {...register(
              `${String(props.path)}.link` as FieldPath<TFieldValues>,
              {
                ...required,
                ...urlValidation,
              },
            )}
          />
        </InputWrapper>

        <Controller
          control={control}
          rules={{ ...required }}
          name={`${String(props.path)}.requestFiles` as FieldPath<TFieldValues>}
          render={({ field, fieldState }) => (
            <InputWrapper
              title={
                <span>
                  Загрузите скриншот с модификациями товара
                  <Whisper
                    placement="right"
                    trigger="hover"
                    speaker={requestTooltip}
                  >
                    <i className="ms-2 bi bi-info-circle color-gray-400 fs-14" />
                  </Whisper>
                </span>
              }
              className="mb-4"
              required
              error={fieldState.error?.message}
            >
              <MediaInput {...field} multiple />
              <div className="fs-14 color-gray-400 mt-1">
                На скриншоте должно быть отчетливо видно выбранные модификации
                товара, их количество и итоговую сумму.
              </div>
            </InputWrapper>
          )}
        />

        <InputWrapper
          title={
            <span>
              Общая стоимость товаров
              <Whisper placement="right" trigger="hover" speaker={priceTooltip}>
                <i className="ms-2 bi bi-info-circle color-gray-400 fs-14" />
              </Whisper>
            </span>
          }
          className="mb-4"
          required
          error={
            // @ts-ignore todo
            get(errors, props.path)?.price?.message
          }
        >
          <div className="input-group">
            <input
              className="form-control"
              type="number"
              min={0}
              step={0.01}
              placeholder="Общая стоимость товаров"
              {...register(
                `${String(props.path)}.price` as FieldPath<TFieldValues>,
                {
                  ...required,
                  ...decimalValidation,
                },
              )}
            />
            <span className="input-group-text">¥</span>
          </div>
          <div className="fs-14 color-gray-400 mt-1">
            Укажите суммарную стоимость выбранных товаров по данной ссылке
          </div>
        </InputWrapper>

        <InputWrapper
          title={<span> Количество товаров (необязательно)</span>}
          className="mb-4"
          error={
            // @ts-ignore todo
            get(errors, props.path)?.requestCount?.message
          }
        >
          <div className="input-group">
            <input
              className="form-control"
              type="number"
              min={0}
              step={1}
              placeholder="Общее количество товаров"
              {...register(
                `${String(props.path)}.requestCount` as FieldPath<TFieldValues>,
                {
                  ...decimalValidation,
                },
              )}
            />
            <span className="input-group-text">шт</span>
          </div>
          <div className="fs-14 color-gray-400 mt-1">
            Пересчёт товара является дополнительной услугой
          </div>
        </InputWrapper>

        <InputWrapper title="Комментарий">
          <textarea
            placeholder="Укажите здесь дополнительную информацию по товару"
            className="form-control"
            {...register(
              `${String(props.path)}.comment` as FieldPath<TFieldValues>,
            )}
          />
        </InputWrapper>
      </div>
    </div>
  );
};
