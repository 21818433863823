import React, { useEffect, useState } from 'react';
import Table, { CellProps } from 'rsuite/Table';
import Pagination from 'rsuite/Pagination';
import { useDebouncedValue, useInputState } from '@mantine/hooks';
import { CleanUser, RbacObject } from '../../../export-types/cleaned-types';
import { usePaginationQs } from '../../../utils/usePagination';
import Toggle from 'rsuite/Toggle';
import { toMoney } from '../../../utils/common.utils';
import { Rbac, RbacSome, useRbac } from '../../common/Rbac';
import Whisper from 'rsuite/Whisper';
import IconButton from 'rsuite/IconButton';
import { WhisperSpeaker } from '../../../utils/whisper-speaker';
import Popover from 'rsuite/Popover';
import Dropdown from 'rsuite/Dropdown';
import { ModalsService } from '../../../services/modals.service';
import { AuthService } from '../../../services/auth.service';
import { ProfileService } from '../../../services/profile.service';
import { AddUserModal } from './add-user-modal.component';
import { LinkUserModal } from './link-user-model.component';
import { UnLinkUserModal } from './unlink-user-model.component';
import { SelfLinkUserModal } from './self-link-user-model.component';
import { useUsers, useUsersCommission } from '../../../api/users';
import { UserBanModal } from './user-ban-modal';
import { AlertService } from '../../../services/alert.service';
import { ConfirmModal } from '../../common/confirm-modal';
import { api } from '../../../services/api';
import { useProfile } from '../../../api/profile';
import {
  UserSelectWithFilter,
  UserWithFilter,
} from '../../common/user-select-with-filter';
import moment from 'moment';
import {
  SetSearchParamsFunction,
  useSearchParamsData,
} from '../purchase-order/useSearchParamsData';
import { useSearchParamsPaginationEffect } from '../purchase-order/useSearchParamsPaginationEffect';
import { useSearchParams } from 'react-router-dom';

export type UserProps = CleanUser & {
  moneyBill: { cny: number; usd: number };
  deliveryDebt: number | null;
  commission?: number;
  promotionCommission?: {
    commission: number;
    endDate: Date;
  };
};

export const UsersClientsTable = () => {
  const { hasPermission } = useRbac();
  const hasUserFullWritePermission = hasPermission(
    RbacObject.User,
    'write:admin',
  );
  const hasUnlinkPermission = hasPermission(RbacObject.User, 'unlink:admin');

  const defaultTake = 15;
  const { data: profile } = useProfile();

  const { take, skip, handleSetPage, handleChangeLimit, page } =
    usePaginationQs(defaultTake);

  useSearchParamsPaginationEffect(handleSetPage);

  const [searchParamsData, setSearchParamsFunctions] = useSearchParamsData();

  const [internalId, setInternalId] = useInputState(
    searchParamsData['internal'] || '',
  );
  const [manager, setManager] = useState<UserWithFilter | null>(null);
  const [takeUnattached, setTakeUnattached] = useState<boolean>(false);
  const [debouncedInternalId] = useDebouncedValue(internalId, 200);
  const [sort, setSort] = React.useState('moneyBillCny');

  useEffect(() => {
    if (searchParamsData['internal'] !== internalId) {
      setSearchParamsFunctions['internal'](internalId);
    }

    if (searchParamsData['manager'] !== (manager?.id || '')) {
      setSearchParamsFunctions['manager'](manager?.id || '');
    }
  }, [internalId, searchParamsData]);

  const { data, isLoading, mutate } = useUsers(
    ['client'],
    debouncedInternalId,
    !manager?.filterCaption ? manager?.id : undefined,
    sort,
    take,
    skip,
    takeUnattached,
  );

  const { data: commissions, isLoading: isCommissionLoading } =
    useUsersCommission(
      data && data.items ? data.items.map((user) => user.id) : [],
      {
        enabled: !isLoading && !!data && !!data.items,
      },
    );

  const sortHandleChange = () => {
    if (sort === 'moneyBillCny') {
      setSort('deliveryDebt');
      return;
    }
    setSort('moneyBillCny');
  };

  const renderMenu =
    (user: UserProps): WhisperSpeaker =>
    ({ onClose, left, top, className }, ref) => {
      const handleSelect = async (eventKey: string | undefined) => {
        onClose();
        if (eventKey === 'edit-user') {
          return ModalsService.createModal(AddUserModal, { user, mutate });
        }
        if (eventKey === 'impersonate') {
          await AuthService.impersonate(user.id);
          await ProfileService.getCurrentUser();
          window.location.replace('/');
          return;
        }
        if (eventKey === 'link-manager') {
          return ModalsService.createModal(
            hasUserFullWritePermission ? LinkUserModal : SelfLinkUserModal,
            { user, mutate },
          );
        }
        if (eventKey === 'unlink-manager') {
          return ModalsService.createModal(UnLinkUserModal, { user, mutate });
        }
        if (eventKey === 'ban') {
          return ModalsService.createModal(UserBanModal, { user }).then(() => {
            AlertService.success();
            mutate();
          });
        }
        if (eventKey === 'unban') {
          return ModalsService.createModal(ConfirmModal, {
            title: 'Разблокировать пользователя?',
            buttonText: `Разблокировать`,
          }).then(() => {
            api.post(`/user/${user.id}/unban`).then(() => {
              AlertService.success();
              mutate();
            });
          });
        }
      };

      return (
        <Popover ref={ref} className={className} style={{ left, top }} full>
          <Dropdown.Menu onSelect={handleSelect}>
            <Dropdown.Item eventKey={'edit-user'}>
              <i className="bi bi-pencil" />
              <span className="ps-2">Изменить</span>
            </Dropdown.Item>
            {user.role !== 'client' && (
              <Rbac object={RbacObject.User} action={'impersonate'}>
                <Dropdown.Item eventKey="impersonate">
                  <i className="bi bi-person-badge" />
                  <span className="ps-2">Залогиниться</span>
                </Dropdown.Item>
              </Rbac>
            )}
            {/* <Dropdown.Item eventKey={'3'}>
              <i className="bi bi-trash" />
              <span className="ps-2">Удалить</span>
            </Dropdown.Item> */}
            {!user.manager && user.role === 'client' && (
              <Dropdown.Item eventKey={'link-manager'}>
                <i className="bi bi-link" />
                <span className="ps-2">Привязать</span>
              </Dropdown.Item>
            )}
            {user.manager &&
              user.role === 'client' &&
              (hasUnlinkPermission || user.manager.id === profile?.id) && (
                <Dropdown.Item eventKey={'unlink-manager'}>
                  <i className="bi bi-link" />
                  <span className="ps-2">Отвязать</span>
                </Dropdown.Item>
              )}
            <Rbac object={RbacObject.User} action={'write:admin'}>
              {user.role === 'client' && !user.banType && (
                <Dropdown.Item eventKey="ban">
                  <i className="bi bi-ban" />
                  <span className="ps-2">Заблокировать</span>
                </Dropdown.Item>
              )}
              {user.role === 'client' && user.banType && (
                <Dropdown.Item eventKey="unban">
                  <i className="bi bi-ban" />
                  <span className="ps-2">Разблокировать</span>
                </Dropdown.Item>
              )}
            </Rbac>
          </Dropdown.Menu>
        </Popover>
      );
    };

  const ActionCell = ({ rowData, dataKey, ...props }: CellProps<UserProps>) => {
    return (
      rowData && (
        <Table.Cell
          {...props}
          className="link-group no-padding-cell pt-2"
          align="right"
        >
          <RbacSome
            checks={[
              { object: RbacObject.User, action: 'write:admin' },
              { object: RbacObject.User, action: 'write:toggles' },
            ]}
          >
            <Whisper
              placement="leftStart"
              trigger="click"
              speaker={renderMenu(rowData)}
            >
              <IconButton
                appearance="subtle"
                icon={<i className="bi bi-three-dots" />}
              />
            </Whisper>
          </RbacSome>
        </Table.Cell>
      )
    );
  };

  const CommissionCell = ({ rowData, ...props }: CellProps<UserProps>) => {
    return (
      rowData && (
        <Table.Cell {...props}>
          {rowData.commission ? rowData.commission + '%' : '-'}{' '}
          {rowData.promotionCommission && (
            <span className="color-red">
              ({rowData.promotionCommission.commission}% до{' '}
              {moment(rowData.promotionCommission.endDate).format('DD.MM.YYYY')}
              )
            </span>
          )}
        </Table.Cell>
      )
    );
  };

  const BalanceCell = ({ rowData, ...props }: CellProps<UserProps>) =>
    rowData && (
      <Table.Cell {...props}>
        {toMoney(rowData.moneyBill?.cny || 0, 'cny')} /{' '}
        {toMoney(rowData.moneyBill?.usd || 0, 'usd')}
      </Table.Cell>
    );

  const DebtCell = ({ rowData, ...props }: CellProps<UserProps>) =>
    rowData && (
      <Table.Cell {...props}>
        {toMoney(rowData.deliveryDebt || 0, 'usd')}
      </Table.Cell>
    );

  const ManagerCell = ({ rowData, ...props }: CellProps<UserProps>) =>
    rowData && <Table.Cell {...props}>{rowData.manager?.name}</Table.Cell>;

  const total = data?.total;
  const items = data?.items?.map((user) => {
    const commissionData = commissions?.list?.find((c) => c.id === user.id);

    return {
      ...user,
      commission: commissionData?.commission,
      promotionCommission: commissionData?.promotionCommission,
    };
  });

  const handleManagerFilterSelect = (value: UserWithFilter | null) => {
    if (value?.filterCaption && value.id === 'unattached') {
      setTakeUnattached(true);
    } else {
      setTakeUnattached(false);
    }
    setManager(value);
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center pb-4">
        <div className="w-75 mr-auto d-flex" style={{ zIndex: 10 }}>
          <input
            className="form-control"
            style={{ width: '35%' }}
            type="text"
            value={internalId}
            placeholder="Поиск по ID / номеру телефона / имени"
            onChange={setInternalId}
          />
          <div className={'mx-1'} style={{ width: '30%' }}>
            <UserSelectWithFilter
              placeholder="Поиск по менеджеру"
              name={'manager'}
              additionalOptions={[
                {
                  id: 'all_users',
                  filterCaption: 'Все клиенты',
                },
                { id: 'unattached', filterCaption: 'Непривязанные' },
              ]}
              access={'salesManager'}
              value={manager}
              onChange={(selectedOption) =>
                handleManagerFilterSelect(selectedOption)
              }
              roles={['employee', 'admin']}
              searchParamsManagerId={searchParamsData['manager']}
              setManager={setManager}
            />
          </div>
        </div>
        <div className="d-flex">
          <Toggle
            checked={sort === 'deliveryDebt'}
            onChange={sortHandleChange}
          />
          <div className="ms-2">Долг CNY / доставка</div>
        </div>
      </div>

      <Table
        data={items}
        headerHeight={57}
        autoHeight
        loading={isLoading || isCommissionLoading}
      >
        <Table.Column flexGrow={0}>
          <Table.HeaderCell>ID</Table.HeaderCell>
          <Table.Cell dataKey="internalId" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Имя</Table.HeaderCell>
          <Table.Cell dataKey="name" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Номер телефона</Table.HeaderCell>
          <Table.Cell dataKey="phone" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.Cell dataKey="email" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Комиссия за выкуп</Table.HeaderCell>
          <CommissionCell />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Баланс аккаунта</Table.HeaderCell>
          <BalanceCell />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Долг по доставке</Table.HeaderCell>
          <DebtCell />
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>Менеджер</Table.HeaderCell>
          <ManagerCell />
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell> </Table.HeaderCell>
          <ActionCell />
        </Table.Column>
      </Table>

      {total && total > take && (
        <div className="mt-4 pb-4">
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="md"
            layout={['pager']}
            total={total}
            limitOptions={[15, 20]}
            limit={take}
            activePage={page}
            onChangePage={handleSetPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      )}
    </>
  );
};
