import { ModalProps } from '../../../services/modals.service';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { InputWrapper } from '../../common/input-wrapper';
import { api } from '../../../services/api';
import { AlertService } from '../../../services/alert.service';
import { CleanPromotion } from '../../../export-types/cleaned-types';
import { UserMultiSelect, User } from '../../common/user-multi-select';
import { useModalClose } from '../../../utils/useModalClose';
import { useUsersCommission } from '../../../api/users';

interface LinkPromotionModalProps extends ModalProps {
  promotion: CleanPromotion;
}

export const LinkPromotionModal = ({
  promotion,
  close,
}: LinkPromotionModalProps) => {
  const { control, handleSubmit, watch } = useForm<{
    users: User[];
  }>({
    defaultValues: promotion.users
      ? {
          users: promotion.users,
        }
      : undefined,
  });

  const { data: userCommission } = useUsersCommission(
    watch('users').map((user) => user.id),
  );

  const onSubmit = handleSubmit(async ({ users }) => {
    api
      .post(`/promotion/${promotion.id}/link`, {
        users: users.map((user) => user.id),
      })
      .then(() => {
        AlertService.success();
        close();
      });
  });

  const modalRef = useModalClose(close);

  return (
    <div className="modal-dialog mw-400" ref={modalRef}>
      <div className="modal-content">
        <div className="modal-header border-bottom-0 p-4">
          <button type="button" className="btn-close" onClick={() => close()} />
        </div>
        <div className="modal-body pt-2 p-4">
          <div className="text-center mb-4 mx-auto">
            <h3>Выберите клиентов для привязки</h3>
          </div>
          <form onSubmit={onSubmit}>
            <Controller
              control={control}
              name="users"
              render={({ field, fieldState }) => (
                <InputWrapper
                  className="mb-3"
                  title="Клиенты"
                  required
                  error={fieldState.error?.message}
                >
                  <UserMultiSelect
                    placeholder="Выберите клиентов"
                    {...field}
                    roles={['client']}
                    styles={{
                      multiValue: (base: any, { data }: any) => {
                        const commission = userCommission?.list.find(
                          (u) => u.id === data.id,
                        );

                        return {
                          ...base,
                          backgroundColor:
                            promotion.users?.find((u) => u.id === data.id) &&
                            commission &&
                            commission.promotionCommission?.id !== promotion.id
                              ? '#28a745'
                              : base.backgroundColor,
                          color:
                            promotion.users?.find((u) => u.id === data.id) &&
                            commission &&
                            commission.promotionCommission?.id !== promotion.id
                              ? '#ffffff'
                              : base.color,
                        };
                      },
                      multiValueLabel: (base: any, { data }: any) => {
                        const commission = userCommission?.list.find(
                          (u) => u.id === data.id,
                        );
                        return {
                          ...base,
                          color:
                            promotion.users?.find((u) => u.id === data.id) &&
                            commission &&
                            commission.promotionCommission?.id !== promotion.id
                              ? '#ffffff'
                              : base.color,
                        };
                      },
                      multiValueRemove: (base: any, { data }: any) => {
                        const commission = userCommission?.list.find(
                          (u) => u.id === data.id,
                        );
                        return {
                          ...base,
                          color:
                            promotion.users?.find((u) => u.id === data.id) &&
                            commission &&
                            commission.promotionCommission?.id !== promotion.id
                              ? '#ffffff'
                              : base.color,
                          ':hover': {
                            backgroundColor:
                              promotion.users?.find((u) => u.id === data.id) &&
                              commission?.promotionCommission?.id ===
                                promotion.id
                                ? 'darkred'
                                : base[':hover']?.backgroundColor,
                            color:
                              promotion.users?.find((u) => u.id === data.id) &&
                              commission?.promotionCommission?.id ===
                                promotion.id
                                ? '#ffffff'
                                : base[':hover']?.color,
                          },
                        };
                      },
                    }}
                  />
                </InputWrapper>
              )}
            />
            <button type="submit" className="btn btn-success w-100 mb-3">
              Сохранить
            </button>
            <button
              type="button"
              className="btn btn-light w-100"
              onClick={() => close()}
            >
              Отменить
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
